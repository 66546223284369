var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CbModal",
        {
          attrs: {
            title: _vm.type === "download" ? "下载报表" : "预览报表",
            "z-index": 6,
          },
          on: { ok: _vm.handleOk, cancel: _vm.cancel },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _vm.isMonth
                ? _c(
                    "a-form-model-item",
                    { ref: "date", attrs: { label: "年月", prop: "date" } },
                    [
                      _c("a-month-picker", {
                        attrs: {
                          placeholder: "请选择年月",
                          "disabled-date": _vm.disabledMonth,
                        },
                        on: { change: _vm.onMonthChange },
                        model: {
                          value: _vm.form.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "date", $$v)
                          },
                          expression: "form.date",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isQuarter
                ? [
                    _c(
                      "a-form-model-item",
                      { ref: "year", attrs: { label: "年", prop: "year" } },
                      [
                        _c(
                          "div",
                          { staticClass: "year-quarter" },
                          [
                            _c("CbYearPicker", {
                              staticClass: "CbYearPicker",
                              attrs: {
                                "disabled-date": _vm.disabledYear,
                                "allow-clear": false,
                              },
                              model: {
                                value: _vm.form.year,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "year", $$v)
                                },
                                expression: "form.year",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        ref: "quarter",
                        attrs: { label: "季度", prop: "quarter" },
                      },
                      [
                        _c(
                          "a-select",
                          {
                            model: {
                              value: _vm.form.quarter,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "quarter", $$v)
                              },
                              expression: "form.quarter",
                            },
                          },
                          _vm._l(_vm.quarterList, function (item) {
                            return _c(
                              "a-select-option",
                              { key: item.value, attrs: { value: item.value } },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.isYear
                ? _c(
                    "a-form-model-item",
                    { ref: "date", attrs: { label: "年", prop: "date" } },
                    [
                      _c("CbYearPicker", {
                        staticClass: "CbYearPicker",
                        attrs: {
                          "disabled-date": _vm.disabledYearfull,
                          "allow-clear": false,
                        },
                        model: {
                          value: _vm.form.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "date", $$v)
                          },
                          expression: "form.date",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isMonth || _vm.isYear
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "报税主体" } },
                    [
                      _c("dictionariesInput", {
                        attrs: {
                          mode: "multiple",
                          parameter: "getEndyearBounsList",
                        },
                        model: {
                          value: _vm.form.taxpaySubjectStaffIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "taxpaySubjectStaffIds", $$v)
                          },
                          expression: "form.taxpaySubjectStaffIds",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isMonth
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "薪资组" } },
                    [
                      _c("dictionariesInput", {
                        attrs: { mode: "multiple", parameter: "listGroup" },
                        model: {
                          value: _vm.form.payrollGroupIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "payrollGroupIds", $$v)
                          },
                          expression: "form.payrollGroupIds",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isQuarter
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "部门" } },
                    [
                      _c("depTreeSelect", {
                        attrs: { multiple: true },
                        model: {
                          value: _vm.form.orgIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "orgIds", $$v)
                          },
                          expression: "form.orgIds",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "排序" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { name: "radioGroup" },
                      model: {
                        value: _vm.form.sort,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sort", $$v)
                        },
                        expression: "form.sort",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: "orgName" } }, [
                        _vm._v(" 按部门排序 "),
                      ]),
                      _c("a-radio", { attrs: { value: "staffNum" } }, [
                        _vm._v(" 按工号排序 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }