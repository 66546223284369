<template>
  <div class="year-picker">
    <a-date-picker
      v-model="yearTime"
      mode="year"
      format="YYYY年"
      :disabled="disabled"
      :open="yearShow"
      :disabled-date="disabledMonth"
      :allow-clear="allowClear"
      @openChange="handleOpenChange"
      @panelChange="panelChange"
    />
  </div>
</template>

<script>
export default {
  name: 'YearPicker',
  components: {},
  model: {
    prop: 'yearTime',
    event: 'change'
  },
  props: {
    yearTime: {
      default: () => {},
      type: [Object, String]
    },
    allowClear: {
      default: false,
      type: Boolean
    },
    disabledDate: {
      type: Function,
      default: () => {
        return {
          flag: false, msg: '年份不在范围内'
        }
      }
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      year: '',
      yearShow: false
    }
  },
  watch: {
    yearTime: {
      handler(newValue) {
        if (!newValue) return
        this.year = newValue
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    panelChange(time) {
      const year = time.format('YYYY')
      this.yearShow = false
      const { flag, msg } = this.disabledDate(year)
      if (flag) {
        this.$message.warning(msg)
      } else {
        this.$emit('change', year)
      }
    },
    disabledMonth(value) {
      return true
    },
    handleOpenChange(status) {
      if (status) {
        this.yearShow = true
      } else {
        this.yearShow = false
      }
    }
  }
}
</script>

<style lang="less" scoped>

</style>
