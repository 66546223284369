var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "year-picker" },
    [
      _c("a-date-picker", {
        attrs: {
          mode: "year",
          format: "YYYY年",
          disabled: _vm.disabled,
          open: _vm.yearShow,
          "disabled-date": _vm.disabledMonth,
          "allow-clear": _vm.allowClear,
        },
        on: { openChange: _vm.handleOpenChange, panelChange: _vm.panelChange },
        model: {
          value: _vm.yearTime,
          callback: function ($$v) {
            _vm.yearTime = $$v
          },
          expression: "yearTime",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }